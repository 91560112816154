import { useEffect, useReducer, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { toast } from "react-hot-toast";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import logoLight from "../../../assets/logo-light.png";
import { Col, Row } from "antd";

function Register({ edit, StudentInfo }) {
  const [values, setValues] = useState(null);
  const { studentID } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
    values,
  });

  const watchGovernorate = watch("governorate");
  const watchLevel = watch("stage");
  const watchPlace = watch("is_online");
  function reducer(state, action) {
    switch (action.type) {
      case "setStates": {
        return {
          ...state,
          states: action.payload,
        };
      }
      case "setGroups": {
        return {
          ...state,
          groups: action.payload,
        };
      }
      case "setSelectedState": {
        return {
          ...state,
          selectedState: action.payload,
        };
      }
      case "setErrors": {
        return {
          ...state,
          errors: action.payload,
        };
      }

      case "setHide": {
        return {
          ...state,
          hide: !state.hide,
        };
      }
      case "setSubmitLoading": {
        return {
          ...state,
          submitLoading: action.payload,
        };
      }
      case "setSubmitEditStudent": {
        return {
          ...state,
          submitEditStudent: action.payload,
        };
      }
      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    hide: false,
    states: [],
    groups: [],
    selectedState: "",
    errors: {},
    submitLoading: false,
    submitEditStudent: { flag: "", dependency: false, data: {} },
  });

  useEffect(() => {
    const stateSelector = document.querySelector(".state-selector");
    stateSelector.addEventListener("change", function handleStateSelected(e) {
      dispatch({
        type: "setSelectedState",
        payload: e.target.selectedIndex,
      });
    });
    async function fetchData() {
      try {
        const res = await axios(process.env.REACT_APP_GOVERNORATES_API);
        const data = await res.data;
        dispatch({
          type: "setStates",
          payload: data?.states,
        });
        dispatch({
          type: "setGroups",
          payload: data?.groups,
        });
      } catch (error) {
        toast.error("حدث خطأ اثناء تحميل المدن من فضلك حاول مرة اخري");
      }
    }
    fetchData();
  }, []);

  const [StudentEditSuccess, StudentEditErrors, StudentEditLoading] = useAxios(
    process.env.REACT_APP_EDIT_STUDENT_INFO_API,
    "POST",
    state.submitEditStudent.flag,
    state.submitEditStudent.dependency,
    state.submitEditStudent.data,
    true
  );

  useEffect(() => {
    if (StudentEditSuccess) {
      reset();
      navigate(-1);
    }
  }, [StudentEditSuccess]);

  useEffect(() => {
    if (StudentInfo) {
      dispatch({
        type: "setSelectedState",
        payload: StudentInfo?.data[0]?.selected_state,
      });
      setValue("governorate", StudentInfo?.data[0]?.governorate);
      setValue("is_online", StudentInfo?.data[0]?.is_online);
      setValue("stage", StudentInfo?.data[0]?.stage);
      delete StudentInfo?.data[0]?.governorate;
      delete StudentInfo?.data[0]?.is_online;
      delete StudentInfo?.data[0]?.stage;
      setValues({
        ...StudentInfo?.data[0],
        active: StudentInfo?.data[0]?.active === 1 ? true : false,
      });
    }
  }, [StudentInfo]);

  console.log(errors, "999999999999");

  const onSubmit = (data) => {
    const studentData = edit
      ? {
          ...data,
          group_id: data?.group_id || null,
          profile_pic: data?.profile_pic[0] || null,
          magnetic_id: data?.magnetic_id || null,
          active: data?.active === true ? 1 : 0,
          id: studentID,
        }
      : {
          ...data,
          first_name: data?.first_name.trim(),
          second_name: data?.second_name.trim(),
          third_name: data?.third_name.trim(),
          fourth_name: data?.fourth_name.trim(),
          mobile: data?.mobile.trim(),
          parent_mobile: data?.parent_mobile.trim(),
          parent_mobile2: data?.parent_mobile2.trim(),
          profile_pic: data?.profile_pic[0],
        };

    if (edit) {
      dispatch({
        type: "setSubmitEditStudent",
        payload: {
          flag: "AddEditStudent",
          dependency: !state.submitEditStudent.dependency,
          data: studentData,
        },
      });
    } else {
      dispatch({
        type: "setSubmitLoading",
        payload: true,
      });
      async function postData() {
        try {
          const res = await axios.post(
            process.env.REACT_APP_REGISTER_API,
            studentData,
            { headers: { "Content-Type": "multipart/form-data" } }
          );

          const data = await res.data;
          toast.success(data?.message);
          dispatch({
            type: "setSubmitLoading",
            payload: false,
          });
          !edit && navigate("/signin");
          reset();
        } catch (error) {
          dispatch({
            type: "setErrors",
            payload: error.response.data?.errors,
          });
          dispatch({
            type: "setSubmitLoading",
            payload: false,
          });
          toast.error(
            "حدث خطأ اثناء انشاء حساب جديد من فضلك قم بمراجعة البيانات "
          );
        }
      }

      postData();
    }
  };

  return (
    <section className="flex h-fit min-h-screen flex-col items-center justify-center gap-0 w-full py-14 dark:bg-dark">
      {!edit && (
        <>
          <HelmetTags
            title="Create Account | Sir karam makram"
            description="Create Account | Sir karam makram"
            index
          >
            <link rel="canonical" href="https://mrkarammakram.com/register" />
          </HelmetTags>

          <div className="mb-24 flex flex-col items-center  justify-center">
            <Link className="h-24 text-4xl " to="/">
              <img className="h-full w-full" src={logoLight} alt="logo" />
            </Link>
            <h1 className="mb-5 mt-10 font-bold ">حساب جديد | سير كريم مكرم</h1>
          </div>
        </>
      )}
      <div className="cardS1 !max-w-[90%]">
        <form action="" onSubmit={handleSubmit(onSubmit)} className="form">
          <Row gutter={[10, 10]} className="flex-row-reverse">
            <Col xs={24} md={12} lg={6}>
              <div className="flex flex-col items-start justify-center  gap-2 md:w-full ">
                <label htmlFor="first_name">الإسم الأول</label>
                <input
                  className="input w-full pl-4    "
                  type="text"
                  id="first_name"
                  placeholder="الإسم الأول"
                  name="first_name"
                  autoComplete="on"
                  {...register("first_name", {
                    required: true,
                    pattern: /^[A-Za-z\s]+$/u,
                    maxLength: 15,
                    minLength: 3,
                  })}
                />
                {errors.first_name && (
                  <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                    {errors.first_name.type === "required" &&
                      "برجاء ملئ هذا الحقل"}
                    {errors.first_name.type === "pattern" &&
                      "يسمح فقط باستخدام الحروف الإنجليزية"}
                    {errors.first_name.type === "maxLength" &&
                      "أقصي عدد للحروف هو 15 حرف"}
                    {errors.first_name.type === "minLength" &&
                      "من فضلك قم بكتابة ٣ أحرف علي الاقل"}
                  </p>
                )}

                {
                  //!-------server errors -----

                  state.errors && state.errors.first_name && (
                    <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                      {state.errors.first_name[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.first_name && (
                      <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                        {StudentEditErrors?.response?.data.errors.first_name[0]}
                      </p>
                    )
                }
              </div>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <div className="flex flex-col items-start justify-center  gap-2 md:w-full ">
                <label htmlFor="second_name"> الإسم الثاني</label>
                <input
                  className="input w-full pl-4    "
                  type="text"
                  id="second_name"
                  placeholder=" الإسم الثاني"
                  name="second_name"
                  autoComplete="on"
                  {...register("second_name", {
                    required: true,
                    pattern: /^[A-Za-z\s]+$/u,

                    maxLength: 15,
                    minLength: 3,
                  })}
                />
                {errors.second_name && (
                  <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                    {errors.second_name.type === "required" &&
                      "برجاء ملئ هذا الحقل"}
                    {errors.second_name.type === "pattern" &&
                      " يسمح فقط باستخدام الحروف الإنجليزية"}
                    {errors.second_name.type === "maxLength" &&
                      "أقصي عدد للحروف هو ١٥ حرف"}
                    {errors.second_name.type === "minLength" &&
                      " من فضلك قم بكتابة ٣ أحرف علي الاقل"}
                  </p>
                )}

                {
                  //!-------server errors -----

                  state.errors && state.errors.second_name && (
                    <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                      {state.errors.second_name[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.second_name && (
                      <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                        {
                          StudentEditErrors?.response?.data.errors
                            .second_name[0]
                        }
                      </p>
                    )
                }
              </div>
            </Col>

            <Col xs={24} md={12} lg={6}>
              <div className="flex flex-col items-start justify-center  gap-2 md:w-full ">
                <label htmlFor="third_name"> الإسم الثالث</label>
                <input
                  className="input w-full pl-4   "
                  type="text"
                  id="third_name"
                  placeholder=" الإسم الثالث"
                  name="third_name"
                  autoComplete="on"
                  {...register("third_name", {
                    required: true,
                    pattern: /^[A-Za-z\s]+$/u,
                    maxLength: 15,
                    minLength: 3,
                  })}
                />
                {errors.third_name && (
                  <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                    {errors.third_name.type === "required" &&
                      "برجاء ملئ هذا الحقل"}
                    {errors.third_name.type === "pattern" &&
                      "يسمح فقط باستخدام الحروف الإنجليزية"}
                    {errors.third_name.type === "maxLength" &&
                      "أقصي عدد للحروف هو ١٥ حرف"}
                    {errors.third_name.type === "minLength" &&
                      " من فضلك قم بكتابة ٣ أحرف علي الاقل"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.third_name && (
                    <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                      {state.errors.third_name[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.third_name && (
                      <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                        {StudentEditErrors?.response?.data.errors.third_name[0]}
                      </p>
                    )
                }
              </div>
            </Col>

            <Col xs={24} md={12} lg={6}>
              <div className="flex flex-col items-start justify-center  gap-2 md:w-full ">
                <label htmlFor="fourth_name"> الإسم الرابع</label>
                <input
                  className="input"
                  type="text"
                  id="fourth_name"
                  placeholder=" الإسم الرابع"
                  name="fourth_name"
                  autoComplete="on"
                  {...register("fourth_name", {
                    required: true,
                    pattern: /^[A-Za-z\s]+$/u,
                    maxLength: 15,
                    minLength: 3,
                  })}
                />
                {errors.fourth_name && (
                  <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                    {errors.fourth_name.type === "required" &&
                      "برجاء ملئ هذا الحقل"}
                    {errors.fourth_name.type === "pattern" &&
                      "يسمح فقط باستخدام الحروف الإنجليزية"}
                    {errors.fourth_name.type === "maxLength" &&
                      "أقصي عدد للحروف هو ١٥ حرف"}
                    {errors.fourth_name.type === "minLength" &&
                      "من فضلك قم بكتابة ٣ أحرف علي الاقل"}
                  </p>
                )}

                {
                  //!-------server errors -----
                  state.errors && state.errors.fourth_name && (
                    <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                      {state.errors.fourth_name[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.fourth_name && (
                      <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                        {
                          StudentEditErrors?.response?.data.errors
                            .fourth_name[0]
                        }
                      </p>
                    )
                }
              </div>
            </Col>

            <Col xs={24} md={12} lg={8}>
              <div className="w-full flex flex-col items-start justify-center  gap-2 md:w-full ">
                <div className="  flex w-full flex-col items-start justify-center gap-2">
                  <label htmlFor="mobile">رقم الطالب</label>
                  <div className="relative w-full">
                    <input
                      className="input  w-full "
                      type="number"
                      id="mobile"
                      placeholder="رقم الطالب"
                      name="mobile"
                      autoComplete="on"
                      {...register("mobile", {
                        required: true,
                        pattern: /^[\d]{11}/,
                        maxLength: 11,
                      })}
                    />
                    <FontAwesomeIcon
                      className="absolute bottom-1/2 left-4 top-[22%] h-6  w-7 translate-y-1/2"
                      icon={faWhatsapp}
                    />
                  </div>
                </div>
                {errors.mobile && (
                  <p className="mt-2 w-full text-end text-[12px] text-red-900 mt-4">
                    {errors.mobile.type === "required" && "برجاء ملئ هذا الحقل"}
                    {errors.mobile.type === "pattern" &&
                      "  من فضلك ادخل رقم هاتف مكون من ١١ رقم"}
                    {errors.mobile.type === "maxLength" &&
                      "  من فضلك ادخل رقم هاتف مكون من ١١ رقم"}
                  </p>
                )}

                {
                  //!-------server errors -----

                  state.errors && state.errors.mobile && (
                    <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                      {state.errors.mobile[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.mobile && (
                      <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                        {StudentEditErrors?.response?.data.errors.mobile[0]}
                      </p>
                    )
                }
              </div>
            </Col>

            <Col xs={24} md={12} lg={8}>
              <div className="w-full flex flex-col items-start justify-center  gap-2 md:w-full ">
                <div className="  flex w-full flex-col items-start justify-center gap-2">
                  <label htmlFor="parent_mobile">رقم ولي الأمر 1</label>
                  <div className="relative w-full">
                    <input
                      className="input w-full "
                      type="number"
                      id="parent_mobile"
                      placeholder="رقم ولي الأمر 1"
                      name="parent_mobile"
                      autoComplete="on"
                      {...register("parent_mobile", {
                        required: true,
                        pattern: /^[\d]{11}/,
                        maxLength: 11,
                      })}
                    />
                    <FontAwesomeIcon
                      className="absolute bottom-1/2 left-4 top-[22%] h-6  w-7 translate-y-1/2"
                      icon={faWhatsapp}
                    />
                  </div>
                </div>
                {errors.parent_mobile && (
                  <p className="mt-2 w-full text-end text-[12px] text-red-900 mt-4">
                    {errors.parent_mobile.type === "required" &&
                      "برجاء ملئ هذا الحقل"}
                    {errors.parent_mobile.type === "pattern" &&
                      "  من فضلك ادخل رقم هاتف مكون من ١١ رقم"}
                    {errors.parent_mobile.type === "maxLength" &&
                      "  من فضلك ادخل رقم هاتف مكون من ١١ رقم"}
                  </p>
                )}

                {
                  //!-------server errors -----

                  state.errors && state.errors.parent_mobile && (
                    <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                      {state.errors.parent_mobile[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors
                      ?.parent_mobile && (
                      <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                        {
                          StudentEditErrors?.response?.data.errors
                            .parent_mobile[0]
                        }
                      </p>
                    )
                }
              </div>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <div className="w-full flex flex-col items-start justify-center  gap-2 md:w-full ">
                <div className="  flex w-full flex-col items-start justify-center gap-2">
                  <label htmlFor="parent_mobile2">رقم ولي الأمر 2</label>
                  <div className="relative w-full">
                    <input
                      className="input  w-full "
                      type="number"
                      id="parent_mobile2"
                      placeholder="رقم ولي الأمر 2"
                      name="parent_mobile2"
                      autoComplete="on"
                      {...register("parent_mobile2", {
                        required: true,
                        pattern: /^[\d]{11}/,
                        maxLength: 11,
                        validate: (value) =>
                          value !== getValues("parent_mobile"),
                      })}
                    />
                    <FontAwesomeIcon
                      className="absolute bottom-1/2 left-4 top-[22%] h-6  w-7 translate-y-1/2"
                      icon={faWhatsapp}
                    />
                  </div>
                </div>
                {errors.parent_mobile2 && (
                  <p className="mt-2 w-full text-end text-[12px] text-red-900 mt-4">
                    {errors.parent_mobile2.type === "required" &&
                      "برجاء ملئ هذا الحقل"}
                    {errors.parent_mobile2.type === "pattern" &&
                      "من فضلك ادخل رقم هاتف مكون من ١١ رقم"}
                    {errors.parent_mobile2.type === "maxLength" &&
                      "من فضلك ادخل رقم هاتف مكون من ١١ رقم"}
                    {errors.parent_mobile2.type === "validate" &&
                      "من فضلك ادخل رقم هاتف مختلف"}
                  </p>
                )}

                {
                  //!-------server errors -----

                  state.errors && state.errors.parent_mobile2 && (
                    <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                      {state.errors.parent_mobile2[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors
                      ?.parent_mobile2 && (
                      <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                        {
                          StudentEditErrors?.response?.data.errors
                            .parent_mobile2[0]
                        }
                      </p>
                    )
                }
              </div>
            </Col>

            {!edit && (
              <>
                <Col xs={24} md={12} lg={12}>
                  <div className="w-full flex flex-col items-start justify-center  gap-2 md:w-full ">
                    <div className="w-full relative flex flex-col items-start justify-center gap-2 ">
                      <label htmlFor="password">الرقم السري</label>
                      <div
                        //!input password relative
                        className="relative-hide relative h-auto w-full"
                      >
                        <input
                          id="password"
                          className="input  w-full"
                          type={`${state.hide ? "text" : "password"}`}
                          placeholder="الرقم السري"
                          name="password"
                          autoComplete="on"
                          {...register("password", {
                            required: true,
                            maxLength: 25,
                            minLength: 6,
                          })}
                        />

                        <div
                          //!eye icons
                          onClick={() =>
                            dispatch({
                              type: "setHide",
                            })
                          }
                          className="hide-pass absolute bottom-1/2 left-4 top-[22%] flex  h-5 w-7 translate-y-1/2  cursor-pointer "
                        >
                          {state.hide ? (
                            <FontAwesomeIcon
                              className="h-full w-full"
                              icon={faEye}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="h-full w-full"
                              icon={faEyeSlash}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {errors.password && (
                      <p className="mt-2 w-full text-end text-[12px] text-red-900 mt-4">
                        {errors.password.type === "required" &&
                          "برجاء ملئ هذا الحقل"}
                        {errors.password.type === "maxLength" &&
                          "أقصي عدد للحروف هو ٢٥ حرف"}
                        {errors.password.type === "minLength" &&
                          "أقل عدد للحروف هو ٦ أحرف"}
                      </p>
                    )}

                    {
                      //!-------server errors -----

                      state.errors && state.errors.password && (
                        <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                          {state.errors.password[0]}
                        </p>
                      )
                    }
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <div className="w-full flex flex-col items-start justify-center  gap-2 md:w-full ">
                    <div className="w-full relative flex flex-col items-start justify-center gap-2  ">
                      <label htmlFor="password_confirmation">
                        تأكيد الرقم السري
                      </label>

                      <div
                        //!input password relative
                        className="relative-hide relative h-auto w-full"
                      >
                        <input
                          id="password_confirmation"
                          className="input  w-full"
                          type={`${state.hide ? "text" : "password"}`}
                          placeholder="تأكيد الرقم السري"
                          name="password_confirmation"
                          autoComplete="on"
                          {...register("password_confirmation", {
                            required: true,
                            maxLength: 25,
                            minLength: 6,
                            validate: (value) =>
                              value === getValues("password"),
                          })}
                        />

                        <div
                          //!eye icons
                          onClick={() =>
                            dispatch({
                              type: "setHide",
                            })
                          }
                          className="hide-pass absolute bottom-1/2 left-4 top-[22%] flex  h-5 w-7 translate-y-1/2  cursor-pointer "
                        >
                          {state.hide ? (
                            <FontAwesomeIcon
                              className="h-full w-full"
                              icon={faEye}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="h-full w-full"
                              icon={faEyeSlash}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    {errors.password_confirmation && (
                      <p className="mt-2 w-full text-end text-[12px] text-red-900 mt-4">
                        {errors.password_confirmation.type === "required" &&
                          "برجاء ملئ هذا الحقل"}
                        {errors.password_confirmation.type === "maxLength" &&
                          "أقصي عدد للحروف هو ٢٥ حرف"}
                        {errors.password_confirmation.type === "minLength" &&
                          "أقل عدد للحروف هو ٦ أحرف"}
                        {errors.password_confirmation.type === "validate" &&
                          "كلمات المرور غير متطابقة"}
                      </p>
                    )}

                    {
                      //!-------server errors -----

                      state.errors && state.errors.password_confirmation && (
                        <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                          {state.errors.password_confirmation[0]}
                        </p>
                      )
                    }
                  </div>
                </Col>
              </>
            )}

            <Col xs={24} md={12}>
              <div className="w-full flex flex-col items-start justify-center  gap-2 md:w-full ">
                <label htmlFor="governorate">المحافظة</label>
                <select
                  className="state-selector input"
                  name="governorate"
                  id="governorate"
                  {...register("governorate", { required: true })}
                >
                  <option hidden value=""></option>
                  {state.states.map((state) => (
                    <option key={state.id} value={state.state}>
                      {state.state}
                    </option>
                  ))}
                </select>
                {errors.governorate && (
                  <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                    {errors.governorate.type === "required" &&
                      "برجاء ملئ هذا الحقل"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.governorate && (
                    <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                      {state.errors.governorate[0]}
                    </p>
                  )
                }{" "}
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.governorate && (
                      <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                        {
                          StudentEditErrors?.response?.data.errors
                            .governorate[0]
                        }
                      </p>
                    )
                }
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className="w-full flex flex-col items-start justify-center  gap-2 md:w-full ">
                {watchGovernorate ? (
                  <>
                    <label htmlFor="area">المدينة</label>
                    <select
                      className="input"
                      name="area"
                      id="area"
                      {...register("area", { required: true })}
                    >
                      {state.states[state.selectedState - 1]?.cities.map(
                        (city) => (
                          <option key={city.id} value={city.id}>
                            {city.city_name_ar}
                          </option>
                        )
                      )}
                    </select>
                    {errors.area && (
                      <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                        {errors.area.type === "required" &&
                          "برجاء ملئ هذا الحقل"}
                      </p>
                    )}
                    {
                      //!-------server errors -----

                      state.errors && state.errors.area && (
                        <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                          {state.errors.area[0]}
                        </p>
                      )
                    }{" "}
                    {
                      //!-------Editing server errors -----

                      StudentEditErrors?.response?.data?.errors &&
                        StudentEditErrors?.response?.data?.errors?.area && (
                          <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                            {StudentEditErrors?.response?.data.errors.area[0]}
                          </p>
                        )
                    }
                  </>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col xs={24} md={24}>
              <div className="w-full flex flex-col items-start justify-center  gap-2 md:w-full ">
                <label htmlFor="stage">المستوي الدراسي</label>
                <select
                  className="input"
                  name="stage"
                  id="stage"
                  {...register("stage", { required: true })}
                >
                  <option value="1">الأول الثانوي</option>
                  <option value="2">الثاني الثانوي</option>
                  <option value="3">الثالث الثانوي</option>
                </select>
                {errors.stage && (
                  <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                    {errors.stage.type === "required" && "برجاء ملئ هذا الحقل"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.stage && (
                    <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                      {state.errors.stage[0]}
                    </p>
                  )
                }{" "}
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.stage && (
                      <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                        {StudentEditErrors?.response?.data.errors.stage[0]}
                      </p>
                    )
                }
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className="w-full flex flex-col items-start justify-center  gap-2 md:w-full ">
                <label htmlFor="is_online">المكان</label>
                <select
                  className="input"
                  name="is_online"
                  id="is_online"
                  {...register("is_online", { required: true })}
                >
                  <option value="1">اونلاين</option>
                  <option value="0">سنتر</option>
                </select>
                {errors.is_online && (
                  <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                    {errors.is_online.type === "required" &&
                      "برجاء ملئ هذا الحقل"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.is_online && (
                    <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                      {state.errors.is_online[0]}
                    </p>
                  )
                }{" "}
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.is_online && (
                      <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                        {StudentEditErrors?.response?.data.errors.is_online[0]}
                      </p>
                    )
                }
              </div>
            </Col>

            <Col xs={24} md={12}>
              <div className="w-full flex flex-col items-start justify-center  gap-2 md:w-full ">
                {watchPlace === "0" || watchPlace === 0 ? (
                  <>
                    <label htmlFor="group_id">الجروب</label>
                    <select
                      className="input"
                      name="group_id"
                      id="group_id"
                      {...register("group_id", {
                        required: edit ? false : true,
                      })}
                    >
                      {state.groups
                        ?.filter(
                          (group) =>
                            parseInt(group.stage) === parseInt(watchLevel)
                        )
                        .map((group) => (
                          <option key={group.id} value={group.id}>
                            {group.name}
                          </option>
                        ))}
                    </select>

                    {errors.group_id && (
                      <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                        {errors.group_id.type === "required" &&
                          "برجاء ملئ هذا الحقل"}
                      </p>
                    )}
                    {
                      //!-------server errors -----

                      state.errors && state.errors.group_id && (
                        <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                          {state.errors.group_id[0]}
                        </p>
                      )
                    }
                    {
                      //!-------Editing server errors -----

                      StudentEditErrors?.response?.data?.errors &&
                        StudentEditErrors?.response?.data?.errors?.group_id && (
                          <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                            {
                              StudentEditErrors?.response?.data.errors
                                .group_id[0]
                            }
                          </p>
                        )
                    }
                  </>
                ) : (
                  ""
                )}
              </div>
            </Col>

            {edit && (
              <Col xs={24}>
                <div className=" flex w-full flex-col  justify-center  gap-2 md:w-full">
                  <label htmlFor="magnetic_id">كرت الحضور</label>
                  <input
                    className="input   w-full"
                    type="text"
                    id="magnetic_id"
                    placeholder="كرت الحضور"
                    name="magnetic_id"
                    autoComplete="on"
                    {...register("magnetic_id", {
                      pattern: /^[A-Za-z0-9-_]*$/,
                      minLength: 3,
                    })}
                  />
                  {errors.magnetic_id && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {errors.magnetic_id.type === "required" &&
                        "Please fill out this field"}
                      {errors.magnetic_id.type === "pattern" &&
                        "Only English letters and numbers are allowed"}
                      {errors.magnetic_id.type === "maxLength" &&
                        "The maximum number of characters is 20 characters"}
                      {errors.magnetic_id.type === "minLength" &&
                        "Please write at least 3 characters"}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    state.errors && state.errors.magnetic_id && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {state.errors.magnetic_id[0]}
                      </p>
                    )
                  }
                  {
                    //!-------Editing server errors -----

                    StudentEditErrors?.response?.data?.errors &&
                      StudentEditErrors?.response?.data?.errors
                        ?.magnetic_id && (
                        <p className="w-full text-start text-[12px] text-blue-900  ">
                          {
                            StudentEditErrors?.response?.data.errors
                              .magnetic_id[0]
                          }
                        </p>
                      )
                  }
                </div>
              </Col>
            )}
            <Col xs={24}>
              <div className="w-full flex flex-col items-start justify-center  gap-2 md:w-full ">
                <label htmlFor="school">المدرسة</label>
                <input
                  className="input w-full"
                  type="text"
                  id="school"
                  placeholder="المدرسة"
                  name="school"
                  autoComplete="on"
                  {...register("school", {
                    required: true,
                    maxLength: 35,
                    minLength: 4,
                  })}
                />
                {errors.school && (
                  <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                    {errors.school.type === "required" && "برجاء ملئ هذا الحقل"}

                    {errors.school.type === "maxLength" &&
                      "أقصي عدد للحروف هو ٣٥ حرف"}
                    {errors.school.type === "minLength" &&
                      " من فضلك قم بكتابة ٤ أحرف علي الاقل"}
                  </p>
                )}
                {
                  //!-------server errors -----

                  state.errors && state.errors.school && (
                    <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                      {state.errors.school[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  StudentEditErrors?.response?.data?.errors &&
                    StudentEditErrors?.response?.data?.errors?.school && (
                      <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                        {StudentEditErrors?.response?.data.errors.school[0]}
                      </p>
                    )
                }
              </div>
            </Col>
            {!edit && (
              <Col xs={24}>
                <div className="w-full flex flex-col items-start justify-center  gap-2 md:w-full ">
                  <label className="w-full text-end " htmlFor="profile_pic">
                    {" "}
                    قم بتحميل صورة شخصية{" "}
                  </label>

                  <input
                    id="profile_pic"
                    className="input   w-full"
                    type="file"
                    accept="image/jpg, image/jpeg, image/png, image/webp"
                    name="profile_pic"
                    {...register("profile_pic", {
                      required: edit ? false : true,
                      validate: (value) => !(value[0]?.size > 5000000),
                    })}
                  />

                  {errors.profile_pic && (
                    <p className="mt-2 w-full text-end text-[12px] text-red-900 mt-4">
                      {errors.profile_pic.type === "required" &&
                        "برجاء اضافة صورة شخصية"}
                      {errors.profile_pic.type === "validate" &&
                        "أقصي حجم للصورة هو ٥ ميجا بايت"}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    state.errors && state.errors.profile_pic && (
                      <p className="w-full text-end text-[12px] text-red-900 mt-4  ">
                        {state.errors.profile_pic[0]}
                      </p>
                    )
                  }
                </div>
              </Col>
            )}
          </Row>
          <button
            disabled={!isValid || state.submitLoading}
            className="login-button  "
            type="submit"
          >
            {state.submitLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : edit ? (
              "تعديل البيانات"
            ) : (
              "إنشاء حساب"
            )}
          </button>
        </form>
        {!edit && (
          <div className="agreement my-10 w-full text-center text-[14px] ">
            لديك حساب بالفعل؟{" "}
            <Link className=" font-bold" to="/signin">
              قم بتسجيل الدخول الآن
            </Link>
          </div>
        )}
      </div>
    </section>
  );
}

export default Register;
