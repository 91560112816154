import { useRef, useState } from "react";
import useFade from "../../../MainComponents/Hooks/useFade";
import SingleCourse from "../../../MainComponents/SingleCourse";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "../../../MainComponents/GlobalContext.jsx";
import SingleChapter from "../../../MainComponents/SingleChapter";

import useAxios from "../../../MainComponents/Hooks/useAxios";
import Loader from "../../../MainComponents/Loader";
import HelmetTags from "../../../MainComponents/HelmetTags";
function StudentLectures() {
  //!---- get all lectures  -------

  const [allLecturesData, allLecturesErrors, allLecturesLoading] = useAxios(
    process.env.REACT_APP_STUDENT_LECTURES_API,
    "GET",
    "GET"
  );

  const TitleWrapper = useRef();
  const [fade] = useFade(TitleWrapper, 100);

  if (allLecturesLoading) {
    return <Loader />;
  }
  if (allLecturesErrors) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>حدث خطأ اثناء تحميل المحاضرات من فضلك حاول مرة اخري</p>;
      </div>
    );
  }
  return (
    <section className=" width  mb-32 min-h-full  dark:bg-dark dark:text-light">
      <HelmetTags title="المحاضرات | Sir karam makram"></HelmetTags>{" "}
      <div ref={TitleWrapper} className="title-wrapper  mt-36">
        <h2
          className={`mb-32 text-center text-5xl font-bold transition-all duration-300 ease-linear ${
            fade ? "translate-y-14 opacity-0" : "translate-y-0 opacity-100"
          } `}
        >
          أفضل الكورسات{" "}
        </h2>
      </div>
      <div className="lectures grid-auto-fit  mb-6">
        {allLecturesData?.data?.length === 0 ? (
          <p>لا يوجد محاضرات</p>
        ) : (
          allLecturesData?.data?.map((Card) => {
            if (Card.is_chapter) {
              return <SingleChapter chapter={Card} key={Card.key} />;
            } else {
              return <SingleCourse lecture={Card} key={Card.key} />;
            }
          })
        )}
      </div>
    </section>
  );
}

export default StudentLectures;
