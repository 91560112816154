import FooterLogo from "../../../assets/logo/logo.png";

import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="aali_tm_section">
      <div className="aali_tm_copyright">
        <div className="aali_container">
          <div className="copyright_inner">
            <div className="top">
              <div className="logo">
                <img src={FooterLogo} alt="aali " />
              </div>
              <div className="social">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/mr.karam.makram">
                      <FaFacebookSquare />
                    </a>
                  </li>
                  <li>
                    <a href="https://youtube.com/user/egyanglophile">
                      <FaYoutube />
                    </a>
                  </li>
                  <li>
                    <a href="https://instagram.com/mr.karam.makram">
                      <FaInstagramSquare />
                    </a>
                  </li>

                  <li>
                    <a href="https://api.whatsapp.com/send?phone=201222825122">
                      <FaWhatsapp />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="bottom">
              <div className="left">
                <p>
                  Created By{" "}
                  <a
                    className="line_anim"
                    href="https://api.whatsapp.com/send?phone=201124265941"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Omar Fathy
                  </a>
                  | © {new Date().getFullYear()} All Rights Reserved.
                </p>
              </div>
            </div>
            <span
              className="border moving_effect"
              data-direction="y"
              data-reverse="yes"
            />
          </div>
        </div>
        <span className="square moving_effect" data-direction="x" />
      </div>
    </div>
  );
};

export default Footer;
