import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import useFade from "../../../MainComponents/Hooks/useFade";
import SingleCourse from "../../../MainComponents/SingleCourse";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import Loader from "../../../MainComponents/Loader";
import HelmetTags from "../../../MainComponents/HelmetTags";

function StudentChapter() {
  const TitleWrapper = useRef();
  const [fade] = useFade(TitleWrapper, 100);
  const { chapterID } = useParams();
  //!---- get chapter info -------
  const [chapterInfoSuccess, chapterInfoErrors, chapterInfoLoading] = useAxios(
    `${process.env.REACT_APP_STUDENT_CHAPTER_INFO_API}${chapterID}`,
    "GET",
    chapterID,
    chapterID
  );

  if (chapterInfoLoading) {
    return <Loader />;
  }
  if (chapterInfoErrors) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>حدث خطأ اثناء تحميل بيانات الفصل من فضلك حاول مرة اخري</p>;
      </div>
    );
  }
  return (
    <section className=" width mb-32 h-full dark:bg-dark dark:text-light">
      <HelmetTags title="الفصول | Sir karam makram"></HelmetTags>{" "}
      <div ref={TitleWrapper} className="title-wrapper  mt-36">
        <h2
          className={`mb-32 text-center text-5xl font-bold transition-all duration-300 ease-linear ${
            fade ? "translate-y-14 opacity-0" : "translate-y-0 opacity-100"
          } `}
        >
          كل المحاضرات{" "}
        </h2>
      </div>
      <div className="lectures grid-auto-fit">
        {chapterInfoSuccess?.data?.length === 0 ? (
          <p>لا يوجد محاضرات لهذا الفصل</p>
        ) : (
          chapterInfoSuccess?.data?.map((lecture) => (
            <SingleCourse lecture={lecture} key={lecture.key} />
          ))
        )}
      </div>
    </section>
  );
}

export default StudentChapter;
