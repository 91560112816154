function Loader() {
  return (
    <div class="loader w-full min-h-screen flex items-center justify-center">
      <span>Anglophile</span>
      <span>Anglophile</span>
    </div>
  );
}

export default Loader;
