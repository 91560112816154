import { useParams } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";

function SessionAbsence() {
  const params = useParams();
  const table = useTable("sessionAbsence", "", "", "", "", params);

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="الغياب | Sir karam makram"></HelmetTags>

      <div className="mb-20 flex flex-col items-end gap-20">
        <h2 className="w-full text-end text-4xl font-bold md:text-center">
          الغياب
        </h2>
        <div className="h-fit  w-full">{table}</div>
      </div>
    </section>
  );
}

export default SessionAbsence;
