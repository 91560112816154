//!visibility is available in edit only not in add
import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function AddEditLecture({ edit }) {
  const [values, setValues] = useState(null);
  const { lectureID, chapterID } = useParams();
  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditLecture": {
        return {
          ...state,
          submitAddEditLecture: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditLecture: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_ADMIN_EDIT_LECTURE_API
    : process.env.REACT_APP_ADMIN_ADD_LECTURE_API;
  //!--------- add edit Lecture -------

  const [LectureAddEditSuccess, LectureAddEditErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditLecture.flag,
    state.submitAddEditLecture.dependency,
    state.submitAddEditLecture.data,
    true
  );

  //!--------- get the Lecture info for editing -------

  const [LectureInfo, LectureInfoErrors] = useAxios(
    `${process.env.REACT_APP_ADMIN_LECTURE_INFO_API}${lectureID}`,
    "GET",
    lectureID,
    lectureID
  );
  useEffect(() => {
    if (LectureInfo) {
      let temp = LectureInfo.data;
      delete temp.img;
      setValues({ ...temp, visibility: temp.visibility === 1 ? true : false });
    }
  }, [LectureInfo]);

  useEffect(() => {
    //!---add actions ----

    if (LectureAddEditSuccess) {
      navigate(-1);
      reset();
    }
    //! make the dependency false again to listen to the next try to submit
  }, [LectureAddEditSuccess]);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched", values });

  const onSubmit = (data) => {
    const finalData = chapterID
      ? {
          ...data,
          chapter_id: chapterID,
          img: data?.img[0] || null,
        }
      : lectureID
      ? {
          ...data,
          id: lectureID,
          img: data?.img[0] || null,
          visibility: data?.visibility === true ? 1 : 0,
        }
      : {
          ...data,
          img: data?.img[0] || null,
        };
    dispatch({
      type: "setSubmitAddEditLecture",
      payload: {
        flag: "AddEditLecture",
        dependency: !state.submitAddEditLecture.dependency,
        data: finalData,
      },
    });
  };

  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="المحاضرات | Sir karam makram"></HelmetTags>
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          {lectureID
            ? "تعديل بيانات المحاضرة"
            : "من فضلك قم بملئ البيانات لإضافة المحاضرة"}
        </h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="cardS1 form md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 "
        >
          {/** Name stage filed */}
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div
              className={`flex w-1/2 flex-col items-end justify-center gap-2 md:order-2  md:w-full `}
            >
              <label className="w-full truncate" htmlFor="stage">
                الصف الدراسى{" "}
              </label>
              <select
                className="input"
                name="stage"
                id="stage"
                {...register("stage", { required: true })}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>

              {errors.stage && (
                <p className="text-[12px] text-red-900 ">
                  {errors.stage.type === "required" && "برجاء ملئ هذا الحقل"}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                LectureAddEditErrors &&
                  LectureAddEditErrors?.response?.data?.errors?.stage && (
                    <p className="w-full text-end text-[12px] text-red-900  ">
                      {LectureAddEditErrors?.response?.data?.errors?.stage[0]}
                    </p>
                  )
              }
            </div>
            <div className="name flex w-1/2 flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="title">الإسم</label>
              <input
                className="input"
                type="text"
                id="title"
                placeholder="الإسم"
                name="title"
                autoComplete="on"
                {...register("title", {
                  required: true,
                  //pattern: /^[A-Za-z]+$/,
                  maxLength: 40,
                  minLength: 3,
                })}
              />
              {errors.title && (
                <p className="w-full text-end text-[12px] text-red-900  ">
                  {errors.title.type === "required" && "برجاء ملئ هذا الحقل"}
                  {errors.title.type === "pattern" &&
                    "يسمح فقط باستخدام الحروف الإنجليزية"}
                  {errors.title.type === "maxLength" &&
                    "أقصي عدد للحروف هو ٤٠ حرف"}
                  {errors.title.type === "minLength" &&
                    "من فضلك قم بكتابة ٣ أحرف علي الاقل"}
                </p>
              )}

              {
                //!-------server errors -----

                LectureAddEditErrors &&
                  LectureAddEditErrors?.response?.data?.errors?.title && (
                    <p className="w-full text-end text-[12px] text-red-900  ">
                      {LectureAddEditErrors?.response?.data?.errors?.title[0]}
                    </p>
                  )
              }
            </div>
          </div>

          {/** price views  days filed */}
          <div className="flex  w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div className=" price flex w-1/3 flex-col items-end justify-center gap-2 md:w-full">
              <label className="w-full truncate" htmlFor="price">
                سعر المحاضرة{" "}
              </label>
              <input
                className="input"
                type="number"
                min={1}
                id="price"
                placeholder="000"
                name="price"
                autoComplete="on"
                {...register("price", {
                  required: true,
                  valueAsNumber: true,
                })}
              />

              {errors.price && (
                <p className="text-[12px] text-red-900 ">
                  {errors.price.type === "required" && "برجاء ملئ هذا الحقل"}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                LectureAddEditErrors &&
                  LectureAddEditErrors?.response?.data?.errors?.price && (
                    <p className="w-full text-end text-[12px] text-red-900  ">
                      {LectureAddEditErrors?.response?.data?.errors?.price[0]}
                    </p>
                  )
              }
            </div>
            <div className=" flex  w-1/3 flex-col items-end justify-center gap-2 md:w-full">
              <label className="w-full truncate" htmlFor="views">
                عدد المشاهدات{" "}
              </label>
              <input
                className="input"
                type="number"
                min={1}
                id="views"
                placeholder="000"
                name="views"
                autoComplete="on"
                {...register("views", {
                  required: true,
                  valueAsNumber: true,
                })}
              />

              {errors.views && (
                <p className="text-[12px] text-red-900 ">
                  {errors.views.type === "required" && "برجاء ملئ هذا الحقل"}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                LectureAddEditErrors &&
                  LectureAddEditErrors?.response?.data?.errors?.views && (
                    <p className="w-full text-end text-[12px] text-red-900  ">
                      {LectureAddEditErrors?.response?.data?.errors?.views[0]}
                    </p>
                  )
              }
            </div>
            <div className=" days flex w-1/3 flex-col items-end justify-center gap-2 md:w-full">
              <label className="w-full truncate" htmlFor="expired_at">
                عدد الأيام{" "}
              </label>
              <input
                className="input"
                type="number"
                min={1}
                id="expired_at"
                placeholder="000"
                name="expired_at"
                autoComplete="on"
                {...register("expired_at", {
                  required: true,
                  valueAsNumber: true,
                })}
              />

              {errors.expired_at && (
                <p className="text-[12px] text-red-900 ">
                  {errors.expired_at.type === "required" &&
                    "برجاء ملئ هذا الحقل"}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                LectureAddEditErrors &&
                  LectureAddEditErrors?.response?.data?.errors?.expired_at && (
                    <p className="w-full text-end text-[12px] text-red-900  ">
                      {
                        LectureAddEditErrors?.response?.data?.errors
                          ?.expired_at[0]
                      }
                    </p>
                  )
              }
            </div>
          </div>
          {/** visibility homework quiz fields */}
          {edit && (
            <div className="flex  w-full items-start justify-end gap-16 md:flex-col md:gap-10 ">
              <div className=" visibility flex w-1/3 flex-col items-end justify-center gap-2 md:w-full">
                <div className="flex w-full items-center justify-end gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="visibility"
                      name="visibility"
                      {...register("visibility", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="visibility"
                    >
                      الرؤية{" "}
                    </label>
                  </div>
                </div>

                {errors.visibility && (
                  <p className="text-[12px] text-red-900 ">
                    {errors.visibility.type === "required" &&
                      "برجاء ملئ هذا الحقل"}{" "}
                  </p>
                )}
                {
                  //!-------server errors -----

                  LectureAddEditErrors &&
                    LectureAddEditErrors?.response?.data?.errors
                      ?.visibility && (
                      <p className="w-full text-end text-[12px] text-red-900  ">
                        {
                          LectureAddEditErrors?.response?.data?.errors
                            ?.visibility[0]
                        }
                      </p>
                    )
                }
              </div>
            </div>
          )}

          {/** Lecture Image  */}
          <div className="flex w-full flex-col items-end gap-2">
            <label className="w-full text-end " htmlFor="img">
              {" "}
              قم بتحميل صورة المحاضرة{" "}
            </label>

            <input
              id="img"
              className="input !pt-[14px]"
              type="file"
              accept="image/jpg, image/jpeg, image/png, image/webp"
              name="img"
              {...register("img", {
                required: values ? false : true,
                validate: (value) => !(value[0]?.size > 5000000),
              })}
            />

            {errors.img && (
              <p className="mt-2 w-full text-end text-[12px] text-red-900">
                {errors.img.type === "required" && "برجاء اضافة صورة المحاضرة"}
                {errors.img.type === "validate" &&
                  "أقصي حجم للصورة هو ٥ ميجا بايت"}
              </p>
            )}
            {
              //!-------server errors -----

              LectureAddEditErrors &&
                LectureAddEditErrors?.response?.data?.errors?.img && (
                  <p className="w-full text-end text-[12px] text-red-900  ">
                    {LectureAddEditErrors?.response?.data?.errors?.img[0]}
                  </p>
                )
            }
          </div>

          <div className=" flex w-full flex-col items-end justify-center gap-2 ">
            <label className="w-full truncate " htmlFor="description">
              الوصف{" "}
            </label>
            <textarea
              className="input"
              {...register("description", {
                maxLength: 155,
                minLength: 3,
              })}
              name="description"
              id="description"
              cols="40"
              rows="5"
            ></textarea>
            {errors.description && (
              <p className="text-[12px] text-red-900 ">
                {errors.description.type === "maxLength" &&
                  "أقصي عدد للحروف هو ١٥٥ حرف"}
                {errors.description.type === "minLength" &&
                  " من فضلك فم بكتابة ٣ أحرف علي الاقل"}
              </p>
            )}
            {
              //!-------server errors -----

              LectureAddEditErrors &&
                LectureAddEditErrors?.response?.data?.errors?.description && (
                  <p className="w-full text-end text-[12px] text-red-900  ">
                    {
                      LectureAddEditErrors?.response?.data?.errors
                        ?.description[0]
                    }
                  </p>
                )
            }
          </div>
          {/** submit */}

          <button
            disabled={
              !isValid || (submitLoading && state.submitAddEditLecture.flag)
            }
            className=" login-button mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEditLecture.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>
                {lectureID ? "تأكيد تعديل بيانات المحاضرة" : "اضافة المحاضرة"}
              </p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddEditLecture;
