import React, { useEffect, useReducer, useState } from "react";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";
import { Col, Popconfirm, Row, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faSpinner } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";

export const AdminViewAllFiles = ({
  allFilesErrors,
  allFilesData,
  setReFetch,
}) => {
  const AuthState = useAuthState();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditMaterial": {
        return {
          ...state,
          submitDeleteFiles: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitDeleteFiles: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  const [AddFilesSuccess, DeleteFilesErrors, DeleteFilesLoading] = useAxios(
    process.env.REACT_APP_ADMIN_DELETE_FILE_API,
    "POST",
    state.submitDeleteFiles.flag,
    state.submitDeleteFiles.dependency,
    state.submitDeleteFiles.data,
    true
  );

  useEffect(() => {
    //!---delete actions ----
    if (AddFilesSuccess) {
      setReFetch((refetch) => !refetch);
    }
  }, [AddFilesSuccess, setReFetch]);

  const handleDeleteFile = (id) => {
    let finalData = { id };
    dispatch({
      type: "setSubmitAddEditMaterial",
      payload: {
        flag: "AddEditMaterial",
        dependency: !state.submitDeleteFiles.dependency,
        data: finalData,
      },
    });
  };

  const saveToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success(`تم نسخ الرابط بنجاح`);
  };

  if (allFilesErrors)
    return (
      <p className="text-center text-2xl font-bold mt-12">{allFilesErrors}</p>
    );

  return (
    <Row gutter={[20, 20]} className="mt-12">
      {allFilesData?.data?.map((file, index) => (
        <Col key={index} xs={24} md={12} lg={6}>
          <div className="flex items-center flex-col gap-6 border-2 border-secondary rounded-2xl overflow-hidden pb-6">
            <img
              className="h-[300px] w-full object-cover	"
              src={file.url}
              alt=""
            />
            <div className="flex items-center gap-2">
              <button
                onClick={() => saveToClipboard(file.url)}
                className="w-fit px-2 rounded-2xl border-2 border-accent bg-accent py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
              >
                <FontAwesomeIcon icon={faCopy} />
              </button>
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions.some((permission) =>
                  permission.name.startsWith("file_delete")
                )) && (
                <Popconfirm
                  okText="تأكيد"
                  okType="danger"
                  cancelText="إلغاء"
                  color="#fecaca"
                  //okButtonProps={{ loading: state.loading }}
                  onConfirm={() => handleDeleteFile(file.id)}
                  title="تأكيد اجراء الحذف؟"
                >
                  <button
                    disabled={DeleteFilesLoading}
                    className="w-100 flex-1 px-2 rounded-2xl border-2 border-secondary bg-secondary py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                  >
                    {DeleteFilesLoading ? (
                      <FontAwesomeIcon spin icon={faSpinner} />
                    ) : (
                      "حذف"
                    )}
                  </button>
                </Popconfirm>
              )}
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};
