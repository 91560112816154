import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import SingleCourse from "../../../../MainComponents/SingleCourse";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../../MainComponents/Loader.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags";

function AdminChapter() {
  const { chapterID } = useParams();
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);

  //!---- get chapter info -------
  const [chapterInfoSuccess, chapterInfoErrors, chapterInfoLoading] = useAxios(
    `${process.env.REACT_APP_ADMIN_CHAPTER_PAGE_API}${chapterID}`,
    "GET",
    chapterID,
    reFetch || chapterID
  );
  return (
    <section
      className={`  h-auto  w-full  px-20 py-20  dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="الفصول | Sir karam makram"></HelmetTags>{" "}
      <div className="mb-20 flex flex-col items-end gap-20">
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("lecture_add")
          )) && (
          <Link
            to={`/admin/lectures/${chapterID}/add-lecture`}
            className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            اضافة محاضرة
          </Link>
        )}
      </div>
      {chapterInfoLoading ? (
        <Loader />
      ) : (
        <div className="lectures grid-auto-fit">
          {chapterInfoSuccess?.data?.length === 0 ? (
            <p>لا يوجد محاضرات لهذا الفصل</p>
          ) : (
            chapterInfoSuccess?.data?.map((lecture) => (
              <SingleCourse
                reFetch={reFetch}
                setReFetch={setReFetch}
                admin
                lecture={lecture}
                key={lecture.key}
              />
            ))
          )}
        </div>
      )}
    </section>
  );
}

export default AdminChapter;
